import React from 'react';
import './styles.css';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const Navbar = (props) => {
  return (
    <>
      <Helmet>
        <title>
          {props.title}
        </title>
      </Helmet>
      <nav class="border split-nav">
        <div class="nav-brand">
          <h3><Link to="/">Email Validation</Link></h3>
        </div>
        <div class="collapsible">
          <input id="collapsible1" type="checkbox" name="collapsible1"  />
          <label for="collapsible1">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </label>
          <div class="collapsible-body">
            <ul class="inline">
              <li><Link to="/">Check Spam Score</Link></li>
              <li><Link to="/how-it-works">How it Works?</Link></li>
              <li><Link to="/about">About</Link></li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
};

export default Navbar;
